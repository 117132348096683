// ** I18n Imports
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {locales} from "./locales"

let language = localStorage.getItem('travel-frontend-language') ? localStorage.getItem('travel-frontend-language') : navigator.language.substring(0, 2);

i18n
    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        lng: language,
        resources: locales,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        }
    })

export default i18n
